import { Box, Input, Typography } from "@mui/material"
import { InputField, OneLineTitle, Title } from "../book-details/Components"
import { ConsentApprovedButton, DarkModeIcon, LightModeIcon } from "../shared/Icons"
import { useSelector } from "react-redux"
import SubmitDropDown from "./SubmitDropDown"
import BoldTextInput from "./BoldTextInput"
import useIsMobile from "../shared/useIsMobile"
import AvailableToPurchaseInput from "./AvailableToPurchaseInput"



const BookForm = ({ fields }) => {
	const mode = useSelector((state) => state.layout.mode)
	
	
	const extraCategories = [
		{ "Illustrator/s": fields.illustrator },
		{ "Graphic designer/s": fields.graphicDesigner },
		{ "Photographer/s": fields.photographer },
		{ "Writer/s": fields.writers },
		{ "Editor/s": fields.editors },
		{ "Proofreading": fields.proofreading },
		{ "Language/s": fields.language },
		{ "Translation/s": fields.translation },
		{ "Repro": fields.repro },
		{ "Printing technique": fields.printingTechnique },
		{ "Binding technique": fields.bindingTechnique },
		{ "Paper/s": fields.paper },
		{ "Typeface/s": fields.typeface },
		{ "Printing house": fields.printingHouse },
		{ "Bindery": fields.bindery },
		{ "Other information": fields.otherInformation },
		{ "ISBN": fields.isbn }
	  ]
	

	const itemStyle = {
		height: "45px",
		display: "flex",
		alignItems: "center",
	}
	const isMobile = useIsMobile()
	// disable inputs when submitting
	return (
		<Box width="auto">
			<Box className={"name-of-the-name"}>
				<Input
					{...fields.nameOfTheName}
					type="text"
					id="name"
					autoComplete="off"
					placeholder="Your name here"
					hidden
				></Input>
			</Box>
			<Box sx={itemStyle} paddingLeft={2}>
				<Title text="TITLE*" />
				<InputField isFullWidth={true} field={fields.title} fontWeight={530} />
			</Box>
			<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
				<Title text="ARTIST/S*" />
				<InputField isFullWidth={true} field={fields.artist} fontWeight={530} />
			</Box>
			{isMobile ? (
				<>
					<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
					<Box sx={itemStyle} width="34%" height="45px" borderRight={1}>
							<Title text="YEAR*" />
							<InputField
								field={fields.year}
								type={"number"}
								fontWeight={530}
							/>
						</Box>
						<Box
							sx={itemStyle}
							width="33%"
							height="45px"
							marginLeft={2}
							borderRight={1}
						>
							<OneLineTitle text="PAGES*" />
							<InputField
								field={fields.pageCount}
								type="number"
								fontWeight={530}
							/>
						</Box>
						<Box sx={itemStyle} width="33%" height="45px" marginLeft={2} >
							<Title text="EDITION*" />
							<InputField
								field={fields.edition}
								type="number"
								fontWeight={530}
							/>
						</Box>
						
					</Box>
					<Box sx={itemStyle} borderTop={1}>
						<Box
							sx={itemStyle}
							width="73%"
							height="45px"
							borderRight={1}
							paddingLeft={2}
						>
							<Title text="DIMENSIONS*" />
							<InputField
								field={fields.dimensionX}
								placeholder={"xx.x"}
								type="number"
								fontWeight={530}
							></InputField>
							<Typography
								marginLeft={2}
								marginRight={2}
								variant="medium18"
								fontWeight={530}
							>
								x
							</Typography>
							<InputField
								field={fields.dimensionY}
								placeholder={"xx.x"}
								type="number"
								fontWeight={530}
							></InputField>
							<Typography
								marginLeft={2}
								marginRight={2}
								variant="medium18"
								fontWeight={530}
							>
								cm
							</Typography>
						</Box>

						<Box
							sx={itemStyle}
							width={"27%"}
							height={"45px"}
							justifyContent={"center"}
						>
							<LightModeIcon mode={mode} />
							<DarkModeIcon mode={mode} />
						</Box>
					</Box>
				</>
			) : (
				<>
					<Box sx={itemStyle} borderTop={1}>
					<Box
							sx={itemStyle}
							width="45%"
							height="45px"
							borderRight={1}
							marginLeft={2}
						>
							<Title text="YEAR*" />
							<InputField
								field={fields.year}
								type={"number"}
								fontWeight={530}
								isFullWidth={true}
							/>
						</Box>
						<Box
							sx={itemStyle}
							width="40%"
							height="45px"
							
							marginLeft={2}
						>
							<Title text="DIMENSIONS*" />
							<InputField
								field={fields.dimensionX}
								placeholder={"xx.x"}
								type="number"
								fontWeight={530}
							></InputField>
							<Typography
								marginLeft={2}
								marginRight={2}
								variant="medium18"
								fontWeight={530}
							>
								x
							</Typography>
							<InputField
								field={fields.dimensionY}
								placeholder={"xx.x"}
								type="number"
								fontWeight={530}
							></InputField>
							<Typography
								marginLeft={2}
								marginRight={2}
								variant="medium18"
								fontWeight={530}
							>
								cm
							</Typography>
							
						</Box>
						<Box
							sx={itemStyle}
							width={"15%"}
							height={"45px"}
							justifyContent={"center"}
						>
							
						</Box>
						
						
					</Box>
					<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
						<Box sx={itemStyle} width="45%" height="45px"  borderRight={1}>
							<OneLineTitle text="PAGE COUNT*" />
							<InputField
								field={fields.pageCount}
								type="number"
								fontWeight={530}
							/>
						</Box>
						<Box sx={itemStyle} width="35%" marginLeft={2} height="45px" borderRight={1}>
							<Title text="EDITION*" />
							<InputField
								field={fields.edition}
								type="number"
								fontWeight={530}
							/>
						</Box>
						
						<Box
							sx={itemStyle}
							width={"20%"}
							height={"45px"}
							justifyContent={"center"}
						>
							<LightModeIcon mode={mode} />
							<DarkModeIcon mode={mode} />
						</Box>
					</Box>
				</>
			)}
			<Box padding={2} borderTop={1} minHeight="200px">
				<Title text="DESCRIPTION*" />
				<InputField
					placeholder={"Book description here.."}
					isMultiline={true}
					isFullWidth={true}
					field={fields.description}
					type="textarea"
					fontWeight={530}
				/>
			</Box>
			<SubmitDropDown fields={fields} />
			{extraCategories.map((category, index) => {
        		const title = Object.keys(category)[0]
        		const value = category[title]  
        
				return (
					<BoldTextInput
					key={index}
					itemStyle={itemStyle}
					title={title}
					field={value}
				/>
				)
      		})}

			<BoldTextInput
				itemStyle={itemStyle}
				title={"Artist/s website"}
				field={fields.artistWebsite}
				/>
			
			<AvailableToPurchaseInput title={"Available to purchase"} itemStyle={itemStyle} mode={mode} />
			<BoldTextInput
				itemStyle={itemStyle}
				title={"Contact information for sale"}
				field={fields.contactInformation}
				placeholder={"Type email here"}
				/>

			<Box padding={2} borderTop={1}>
				
					
				<Typography>I give my consent for WEBSITE FOR FINNISH ARTBOOKS to publish my images and all the information I submit through the form on the website and Instagram. <ConsentApprovedButton mode={mode} size={14}/></Typography>
				
				
			</Box>
			
		</Box>
	)
}

export default BookForm
