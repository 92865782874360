//import TestImage from "./../assets/testimg.png"

import BookForm from "./submit/BookForm"

import { colors } from "../theme"

import { UploadIcon, XIcon } from "./shared/Icons"
import { useField } from "./book-details/Components"

import { useRef, useState } from "react"
import { useSubmitBookMutation } from "../store/apiSlice"
import { useDispatch, useSelector } from "react-redux"
import {
	addImage,
	addThumbnail,
	removeImage,
	removeThumbnail,
	resetSubmitBook,
} from "../reducers/submitBookSlice"
import { useNavigate } from "react-router-dom"
import { Box, ImageList, ImageListItem, Typography } from "@mui/material"
import { DropDownCloseIcon } from "./shared/Icons"
import PreviewView from "./PreviewView"

import Loading from "./shared/Loading"
import { useNotify } from "../utils/confirm"
import useIsMobile from "./shared/useIsMobile"
import { yellow } from "@mui/material/colors"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"

const SubmitView = () => {
	const title = useField()
	const artist = useField()
	const edition = useField()
	const pageCount = useField()
	const dimensionX = useField()
	const dimensionY = useField()
	const year = useField()
	const description = useField()
	const printingTechnique = useField()
	const bindingTechnique = useField()
	const isbn = useField()
	const graphicDesigner = useField()
	const otherDesigner = useField()
	const illustrator = useField()
	const photographer = useField()
	const repro = useField()
	const writers = useField()
	const editors = useField()
	const proofreading = useField()
	const language = useField()
	const translation = useField()
	const typeface = useField()
	const paper = useField()
	const printingHouse = useField()
	const bindery = useField()
	const otherInformation = useField()
	const contactInformation = useField()
	const artistWebsite = useField()
	
	const nameOfTheName = useField()



	const itemStyle = {
		height: "45px",
		display: "flex",
		alignItems: "center",
	}

	const fields = {
		title,
		artist,
		edition,
		pageCount,
		dimensionX,
		dimensionY,
		year,
		description,
		printingTechnique,
		bindingTechnique,
		isbn,
		graphicDesigner,
		otherDesigner,
		illustrator,
		photographer,
		repro,
		writers,
		editors,
		proofreading,
		language,
		translation,
		typeface,
		paper,
		printingHouse,
		bindery,
		otherInformation,
		artistWebsite,
		contactInformation,
		nameOfTheName,
	}

	const [notify, Notification] = useNotify()

	const [previewBook, setPreviewBook] = useState(null)
	const dispatch = useDispatch()
	const mode = useSelector((state) => state.layout.mode)
	const [previewOpen, setPreviewOpen] = useState(false)
	const [submitBook, result] = useSubmitBookMutation({
		fixedCacheKey: "shared-submit-book",
	})
	const categoryTags = useSelector((state) => state.submitBook.categoryTags)
	const availableToPurchase = useSelector((state) => state.submitBook.availableToPurchase)
	const consentApproved = useSelector((state) => state.submitBook.consentApproved)
	const manualCategoryTags = useSelector((state) => state.submitBook.manualCategoryTags)
	const manualPublisher = useSelector((state) => state.submitBook.manualPublisher)
	const publisherTag = useSelector((state) => state.submitBook.publisherTag)
	const themes = useSelector((state) => state.submitBook.themes)
	const genres = useSelector((state) => state.submitBook.genres)
	
	const images = useSelector((state) => state.submitBook.images)
	const thumbnail = useSelector((state) => state.submitBook.thumbnail)
	const navigate = useNavigate()

	const imageUploadButtonRef = useRef()
	const thumbnailUploadButtonRef = useRef()

	const handleUploadButtonClick = () => {
		imageUploadButtonRef.current.click()
	}

	const handleThumbnailButtonClick = () => {
		thumbnailUploadButtonRef.current.click()
	}

	const pushCategoryTag = (tags, tag) =>{
		if (tag !== ""){
			tags.push(tag)
		}
		return tags
	}
	

	const handleSubmit = async () => {
		const formData = new FormData()
		if (fields.nameOfTheName.value) {
			return
		}

		if (!consentApproved){
			await notify("Please approve the consent before submitting, confirming that WEBSITE FOR FINNISH ARTBOOKS can collect and publish the information and images you provide on their website and Instagram.")
			return
		}

		if (images.length < 1) {
			await notify("Images are required")
		} else if (!thumbnail) {
			await notify("Thumbnail is required")
		} else {
			formData.append("images", thumbnail.data)
			for (let i = 0; i < images.length; i++) {
				formData.append("images", images[i].data)
			}
			
			for (let key in fields) {
				formData.append(key, fields[key].value)
			}
			
			formData.append("availableToPurchase", availableToPurchase)

			let publisher = ""

			if (manualPublisher !== ""){
				publisher = manualPublisher
			}else{
				publisher = publisherTag
			}
			formData.append("publisher", publisher)
			
			let allCategoryTags = [...categoryTags]

			allCategoryTags = pushCategoryTag(allCategoryTags, manualCategoryTags.genre)
			allCategoryTags = pushCategoryTag(allCategoryTags, manualCategoryTags.theme)
			allCategoryTags = pushCategoryTag(allCategoryTags, publisher)
			
			formData.append("categoryTags", allCategoryTags)
			const allGenres = pushCategoryTag([...genres], manualCategoryTags.genre)
			const allThemes = pushCategoryTag([...themes], manualCategoryTags.theme)
			formData.append("genres", allGenres)
			formData.append("themes", allThemes)
			console.log(allGenres, allThemes)
			submitBook(formData)
				.unwrap()
				.then(() => {
					Object.values(fields).forEach((field) => field.onReset())
					navigate("/thankyou")
					dispatch(resetSubmitBook())
				})
				.catch((error) => 
					{ 
						console.log(error)
						notify(error.data.message) 
					}
				)
		}
	}

	const handlePreview = () => {
		const fieldValues = {}
		Object.keys(fields).forEach((key) => {
			fieldValues[key] = fields[key].value
		})
		const previewImages = images.map((i) => ({
			url: i.preview,
		}))

		const book = { images: previewImages, ...fieldValues }
		setPreviewBook(book)
		setPreviewOpen(true)
	}

	const handleImageChange = (event) => {
		for (const file of event.target.files) {
			const img = {
				id: Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
				preview: URL.createObjectURL(file),
				data: file,
				filename: file.name,
			}
			dispatch(addImage(img))
		}
	}

	const handleThumbnailChange = (event) => {
		const file = event.target.files[0]
		const img = {
			id: Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
			preview: URL.createObjectURL(file),
			data: file,
			filename: file.name,
		}
		dispatch(addThumbnail(img))
	}

	const handleImageRemove = (id) => {
		dispatch(removeImage(id))
	}

	const handleThumbnailRemove = () => {
		dispatch(removeThumbnail())
	}
	const isMobile = useIsMobile()

	if (isMobile) {
		return (
			<Box width={"100%"}>
				<Box
					backgroundColor={yellow[100]}
					fontSize={12}
					borderBottom={1}
					color={"black"}
					padding={"4px"}
					display={"flex"}
				>
					<Box margin={"2px"} marginBottom={0}>
						<WarningAmberIcon sx={{ fontSize: "12px" }} />
					</Box>
					<Box>
						It is recommended to use the desktop version to submit your
						publication.
					</Box>
				</Box>
				<BookForm handleSubmit={handleSubmit} fields={fields} />
				<Notification />
				<input
					ref={imageUploadButtonRef}
					onChange={handleImageChange}
					type={"file"}
					multiple
					hidden
				></input>

				<input
					ref={thumbnailUploadButtonRef}
					onChange={handleThumbnailChange}
					type={"file"}
					hidden
				></input>
				<Loading isLoading={result.isLoading}></Loading>
				{previewOpen ? (
					<PreviewView
						book={previewBook}
						onClose={() => setPreviewOpen(false)}
					></PreviewView>
				) : null}

				<Box display={"flex"} flexDirection={"column"} width={"100%"}>
					<Box
						display={"flex"}
						width={"100%"}
						flexDirection={"column"}
						minHeight={"400px"}
						sx={{ backgroundColor: "background.inverted" }}
						alignItems={"center"}
						justifyContent={"center"}
					>
						{images.length < 1 ? (
							<>
								<Box
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}
									sx={{ cursor: "pointer" }}
									onClick={handleUploadButtonClick}
								>
									<Box>
										<UploadIcon mode={mode} />
									</Box>
									<Box display={"flex"} flexDirection={"column"}>
										<Typography
											textAlign={"center"}
											margin={"10px"}
											color={colors.grey}
											variant="medium22"
										>
											ADD IMAGES
										</Typography>
										<Typography
											textAlign={"center"}
											margin={"2px"}
											color={colors.grey}
										>
											The images can be a maximum of 2000 px in width and a maximum file size of 2 MB.
										</Typography>
										<Typography
											textAlign={"center"}
											margin={"2px"}
											color={colors.grey}
										>
											You can upload up to 15 images.
										</Typography>
									</Box>
								</Box>
							</>
						) : (
							<Box
								display={"flex"}
								width={"100%"}
								height={"100%"}
								flexDirection={"column"}
								sx={{ backgroundColor: "background.inverted" }}
							>
								<Box
									overflow={"scroll"}
									sx={{ backgroundColor: "background.inverted" }}
								>
									<Box
										display={"flex"}
										alignItems={"center"}
										paddingLeft={"8px"}
										height={"45px"}
										width={"100%"}
										justifyContent={"space-between"}
										paddingRight={2}
									>
										<Typography color={colors.grey} fontSize={30}>
											THUMBNAIL
										</Typography>
										<Typography
											className="clickable"
											color={colors.grey}
											fontSize={24}
											onClick={handleThumbnailButtonClick}
										>
											{thumbnail ? "Edit" : "Add"} thumbnail
										</Typography>
									</Box>
									<ImageList cols={4} sx={{ width: "100%", padding: "12px" }}>
										<>
											{thumbnail ? (
												<ImageListItem key={thumbnail.id}>
													<img src={thumbnail.preview}></img>
													<XIcon
														mode={mode}
														handleClick={() =>
															handleThumbnailRemove(thumbnail.id)
														}
														custom={true}
													></XIcon>
												</ImageListItem>
											) : null}
										</>
									</ImageList>
									<Box
										display={"flex"}
										alignItems={"center"}
										paddingLeft={"8px"}
										height={"45px"}
										width={"100%"}
										justifyContent={"space-between"}
										paddingRight={2}
									>
										<Typography color={colors.grey} fontSize={30}>
											PHOTOS
										</Typography>
										<Typography
											className="clickable"
											color={colors.grey}
											fontSize={24}
											onClick={handleUploadButtonClick}
										>
											Add more photos
										</Typography>
									</Box>
									<ImageList cols={4} sx={{ width: "100%", padding: "12px" }}>
										{images &&
											images.map((image, index) => (
												<>
													<ImageListItem key={index}>
														<img src={image.preview}></img>
														<XIcon
															mode={mode}
															handleClick={() => handleImageRemove(image.id)}
															custom={true}
														></XIcon>
													</ImageListItem>
												</>
											))}
									</ImageList>
								</Box>
							</Box>
						)}
					</Box>
				</Box>
				<Box
					display={"flex"}
					flexDirection={"column"}
					width={"50%"}
					overflow={"scroll"}
					borderRight={1}
				></Box>
				<Box
					display={"flex"}
					sx={itemStyle}
					borderTop={1}
					borderBottom={1}
					justifyContent={"flex-end"}
				>
					<Box marginRight={"12px"} display={"flex"}>
						<Typography variant="medium22">SUBMIT</Typography>
						<DropDownCloseIcon mode={mode} handleClick={handleSubmit} />
					</Box>
				</Box>
			</Box>
		)
	}

	return (
		<>
			<Notification />
			<input
				ref={imageUploadButtonRef}
				onChange={handleImageChange}
				type={"file"}
				multiple
				hidden
			></input>

			<input
				ref={thumbnailUploadButtonRef}
				onChange={handleThumbnailChange}
				type={"file"}
				hidden
			></input>
			<Loading isLoading={result.isLoading}></Loading>
			{previewOpen ? (
				<PreviewView
					book={previewBook}
					onClose={() => setPreviewOpen(false)}
				></PreviewView>
			) : null}

			<Box display={"flex"} flexDirection={"column"} width={"50%"}>
				<Box
					display={"flex"}
					width={"100%"}
					flexDirection={"column"}
					overflow={"scroll"}
					height={"calc(100vh - 235px)"}
					sx={{ backgroundColor: "background.inverted" }}
					alignItems={"center"}
					justifyContent={"center"}
				>
					{images.length < 1 ? (
						<>
							<Box
								display={"flex"}
								flexDirection={"column"}
								alignItems={"center"}
								sx={{ cursor: "pointer" }}
								onClick={handleUploadButtonClick}
							>
								<Box>
									<UploadIcon mode={mode} />
								</Box>
								<Box display={"flex"} flexDirection={"column"}>
									<Typography
										textAlign={"center"}
										margin={"10px"}
										color={colors.grey}
										variant="medium22"
									>
										UPLOAD IMAGES
									</Typography>
									<Typography
										textAlign={"center"}
										margin={"2px"}
										color={colors.grey}
									>
										The images can be a maximum of 2000 px in width and a maximum file size of 2 MB.
									</Typography>
									<Typography
										textAlign={"center"}
										margin={"2px"}
										color={colors.grey}
									>
										You can upload up to 15 images.
									</Typography>
								</Box>
							</Box>
						</>
					) : (
						<Box
							display={"flex"}
							width={"100%"}
							height={"100%"}
							flexDirection={"column"}
							sx={{ backgroundColor: "background.inverted" }}
						>
							<Box
								overflow={"scroll"}
								sx={{ backgroundColor: "background.inverted" }}
							>
								<Box
									display={"flex"}
									alignItems={"center"}
									paddingLeft={"8px"}
									height={"45px"}
									width={"100%"}
									justifyContent={"space-between"}
									paddingRight={2}
								>
									<Typography color={colors.grey} fontSize={30}>
										THUMBNAIL
									</Typography>
									<Typography
										className="clickable"
										color={colors.grey}
										fontSize={24}
										onClick={handleThumbnailButtonClick}
									>
										{thumbnail ? "Edit" : "Add"} thumbnail
									</Typography>
								</Box>
								<ImageList cols={4} sx={{ width: "100%", padding: "12px" }}>
									<>
										{thumbnail ? (
											<ImageListItem key={thumbnail.id}>
												<img src={thumbnail.preview}></img>
												<XIcon
													mode={mode}
													handleClick={() =>
														handleThumbnailRemove(thumbnail.id)
													}
													custom={true}
												></XIcon>
											</ImageListItem>
										) : null}
									</>
								</ImageList>
								<Box
									display={"flex"}
									alignItems={"center"}
									paddingLeft={"8px"}
									height={"45px"}
									width={"100%"}
									justifyContent={"space-between"}
									paddingRight={2}
								>
									<Typography color={colors.grey} fontSize={30}>
										IMAGES
									</Typography>
									<Typography
										className="clickable"
										color={colors.grey}
										fontSize={24}
										onClick={handleUploadButtonClick}
									>
										Add more images
									</Typography>
								</Box>
								<ImageList cols={4} sx={{ width: "100%", padding: "12px" }}>
									{images &&
										images.map((image, index) => (
											<>
												<ImageListItem key={index}>
													<img src={image.preview}></img>
													<XIcon
														mode={mode}
														handleClick={() => handleImageRemove(image.id)}
														custom={true}
													></XIcon>
												</ImageListItem>
											</>
										))}
								</ImageList>
							</Box>
						</Box>
					)}
				</Box>
			</Box>
			<Box
				display={"flex"}
				flexDirection={"column"}
				width={"50%"}
				overflow={"scroll"}
				borderRight={1}
				borderBottom={1}
			>
				<BookForm handleSubmit={handleSubmit} fields={fields} />
				<Box
					display={"flex"}
					sx={itemStyle}
					borderTop={1}
					borderBottom={1}
					justifyContent={"space-between"}
				>
					<Box marginLeft={"16px"} display={"flex"}>
						<Typography variant="medium22">PREVIEW</Typography>
						<DropDownCloseIcon mode={mode} handleClick={handlePreview} />
					</Box>
					<Box marginRight={"12px"} display={"flex"}>
						<Typography variant="medium22">SUBMIT</Typography>
						<DropDownCloseIcon mode={mode} handleClick={handleSubmit} />
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default SubmitView
