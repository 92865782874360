import { Box, Typography, Link } from "@mui/material"
import { NavLink } from "react-router-dom"


const AboutView = () => {



    return (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}  padding={4} border={1} borderTop={0}>
            <Box width={"675px"}>
                <Box  padding={"4px"}>
                <Box>
                    <Typography> If you would like to submit an artbook, please fill out the form <Link to={"/submit"} component={NavLink} >here.</Link></Typography> 

                    <Typography> Please read the guidelines below before submitting an artbook.</Typography> 
                </Box>
                <Box marginTop={2}>
                    <Typography variant="h4" marginBottom={2}>Submission Guidelines</Typography>
                    
                    <Typography > Please send at least 5 images, and up to 15 images maximum (plus thumbnail).</Typography> 

                    <Typography> The images can be a maximum of 2000 px in width and a maximum file size of 2 MB.</Typography> 

                    <Typography> The thumbnail must be 1200 pixels in width and 1400 pixels in height and a maximum file size of 2 MB.</Typography> 

                    <Typography> The following fields in the form are mandatory: title, artist, edition, page count, dimensions, year, description. We hope that you also complete the other fields where applicable.</Typography> 

                    <Typography> If you have any questions or problems, please send an E-MAIL to <Link href="mailto:websiteforfinnishartbooks@gmail.com"> websiteforfinnishartbooks@gmail.com </Link></Typography> 

                    <Typography> Thank you for submitting to Website for Finnish Artbooks!</Typography>
                </Box>
            </Box>
                <Box marginTop={2}>
                    <Typography variant="h4" marginBottom={2}>
                        Website for Finnish Artbooks
                    </Typography>
                    
                    <Typography>
                        Website for Finnish Artbooks presents artbooks. To buy an art book you see here, please contact the relevant artist directly. Any sales are between artist and buyer only. Contact information of the artist is available on Website for Finnish Artbooks where provided by them upon submission. Website for Finnish Artbooks is a non-commercial and non-profit platform.
                    </Typography>
                    <Box marginTop={4}>
                        <Typography>
                            Concept and Admin: Peppi-Lotta Heinonen, Tuomas Linna, Olivia Viitakangas
                        </Typography>
                        
                        <Typography>
                            Design: <Link href="https://peppi-lotta.com" target="_blank" rel="noopener noreferrer">Peppi-Lotta Heinonen</Link>
                        </Typography>
                        
                        <Typography>
                            Development: Kalle Lehikoinen
                        </Typography>
                        
                        <Typography>
                            Thank you: <Link href="https://www.taike.fi/en" target="_blank" rel="noopener noreferrer">Art Promotion Centre Finland</Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AboutView