import { Box, Grid } from "@mui/material"
import TagItem from "../shared/TagItem"
import { useDispatch, useSelector } from "react-redux"
import {
	removeFilterTag,
	removeArtistTag,
	removePublisherTag,
} from "../../reducers/filterSlice"
import { forwardRef } from "react"

const TagBox = forwardRef(({ lol }, ref) => {
	const filterTags = useSelector((state) => state.filter.query.filterTags)
	const artistTags = useSelector((state) => state.filter.query.artistTags)
	const publisherTags = useSelector((state) => state.filter.query.publisherTags)
	const dispatch = useDispatch()
	const removeTag = (text) => {
		dispatch(removeFilterTag(text))
	}
	const removeATag = (text) => {
		dispatch(removeArtistTag(text))
	}
	const removePTag = (text) => {
		dispatch(removePublisherTag(text))
	}
	console.log(lol)
	return (
		<Box minHeight={"300px"} margin={"18px"}>
			<Grid ref={ref} container spacing={1}>
				{filterTags &&
					filterTags.map((item, index) => (
						<Grid key={index} item>
							<TagItem text={item} handleRemove={removeTag}></TagItem>
						</Grid>
					))}
				{artistTags &&
					artistTags.map((item, index) => (
						<Grid key={index} item>
							<TagItem text={item} handleRemove={removeATag}></TagItem>
						</Grid>
					))}
				{publisherTags &&
					publisherTags.map((item, index) => (
						<Grid key={index} item>
							<TagItem text={item} handleRemove={removePTag}></TagItem>
						</Grid>
					))}
			</Grid>
		</Box>
	)
})

TagBox.displayName = "TagBox"

export default TagBox
